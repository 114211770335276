/* Steps */

.ba-step{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 100px;
    .ba-step-element{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        min-width: 100px;
        cursor: not-allowed;
        position: relative;
        .ba-step-icon{
            box-sizing: border-box;
            border-radius: 50%;
            background-color: #fff;
            border: 3px solid #D0DAED;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            builderall-icon{
                --builderall-icon-size: 22px;
                --builderall-icon-color: #95ACCC;
            }
        }
        // Inside elements
        &:not(:last-child) .ba-step-icon::after,
        &:not(:first-child) .ba-step-icon::before{
            content: "";
            background-color: #D0DAED;
            width: 20%;
            height: 3px;
            position: absolute;
            top: 17px;
        }
        &:not(:last-child) .ba-step-icon::after{
            right: 0;
            border-radius: 5px 0 0 5px;
        }
        &:not(:first-child) .ba-step-icon::before{
            left: 0;
            border-radius: 0 5px 5px 0;
        }
        // Between elements
        &:not(:last-child)::after,
        &:not(:first-child)::before{
            content: "";
            background-color: #D0DAED;
            width: 50px;
            height: 3px;
            position: absolute;
            top: 17px;
        }
        &:not(:last-child)::after{
            left: 100%;
        }
        &:not(:first-child)::before{
            right: 100%;
        }
        .ba-step-title{
            font-weight: 200;
            color: $black17;
        }
        &.ba-step-active{
            cursor: pointer;
            .ba-step-icon{
                border-color: $green1;
                background-color: $green1;
                builderall-icon{
                    --builderall-icon-color: #fff;
                }
            }
            .ba-step-title{
                color: #253445;
                font-weight: 700;
            }
            &:not(:first-child)::before,
            &:not(:last-child)::after,
            &:not(:first-child) .ba-step-icon::before,
            &:not(:last-child) .ba-step-icon::after{
                background-color: $green1;
            }
        }
    }
}