/* Fonts */


// Mulish
$mulish: "Mulish";

// Montserrat
$montserrat: "Montserrat";

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}

h1{
    font-size: 56px;
    font-weight: 700;
}

h2{
    font-size: 48px;
}

h3{
    font-size: 36px;
}

h4{
    font-size: 32px;
}

h5{
    font-size: 24px;
    font-weight: 600;
}

h6{
    font-size: 17.6px;
    font-weight: 600;
}

p{
    font-size: 16px;
    font-weight: 600;
}

hr{
    border-top: 1px solid $black18;
}

// Text align
.ba-text-center{
    text-align: center;
}

.ba-text-left{
    text-align: left;
}

.ba-text-right{
    text-align: right;
}

// Text transform
.ba-text-uppercase{
    text-transform: uppercase;
}

.ba-text-lowercase{
    text-transform: lowercase;
}

.ba-text-capitalize{
    text-transform: capitalize;
}

// Text decoration
.ba-text-underline{
    text-decoration: underline;
}

.ba-text-line-through{
    text-decoration: line-through;
}

.ba-text-overline{
    text-decoration: overline;
}

// Mulish font
.ba-mulish-100{
    font-weight: 100;
}
.ba-mulish-200{
    font-weight: 200;
}
.ba-mulish-300{
    font-weight: 300;
}
.ba-mulish-400{
    font-weight: 400;
}
.ba-mulish-500{
    font-weight: 500;
}
.ba-mulish-600{
    font-weight: 600;
}
.ba-mulish-700{
    font-weight: 700;
}
.ba-mulish-800{
    font-weight: 800;
}
.ba-mulish-900{
    font-weight: 900;
}
.ba-mulish-bold{
    font-weight: bold;
}

// Montserrat font
.ba-montserrat-100{
    font-weight: 100;
}
.ba-montserrat-200{
    font-weight: 200;
}
.ba-montserrat-300{
    font-weight: 300;
}
.ba-montserrat-400{
    font-weight: 400;
}
.ba-montserrat-500{
    font-weight: 500;
}
.ba-montserrat-600{
    font-weight: 600;
}
.ba-montserrat-700{
    font-weight: 700;
}
.ba-montserrat-800{
    font-weight: 800;
}
.ba-montserrat-900{
    font-weight: 900;
}
.ba-montserrat-bold{
    font-weight: bold;
}
  