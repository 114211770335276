.ba-card-breadcrumb{
    padding: 0 20px !important;
    .ba-breadcrumb{
        display: flex;
        height: 54.4px;
        align-items: center;
        gap: 16px;
        color: $light23;
        a{
            text-decoration: none;
        }
        .ba-line{
            height: 32px;
            width: 1px;
            border: none;
            background-color: $black20;
            margin: 0;
            padding: 0;
        }
        .ba-breadcrumb-ol{
            display: flex;
            align-items: center;
            text-align: center;
            margin: 0;
            padding: 0;
            list-style: none;
            gap: 6.4px;
            .ba-breadcrumb-item{
                font-weight: 600;
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 8px;
                builderall-icon{
                    --builderall-icon-size: 28px;
                    --builderall-icon-color: var(--light23);
                    &:hover{
                        --builderall-icon-color: var(--blue1);
                    }
                }
                a{
                    color: inherit;
                }
                &:not(:last-child)::after{
                    width: 16px;
                    height: 16px;
                    content: close-quote;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M6 13.1662L10 9.1662L6 5.1662' stroke='currentColor' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
                }
                &:hover,
                &:last-child{
                    color: $blue1;
                    transition: .4s;
                }
                &.ba-main-item:hover{
                    color: inherit;
                }
            }
        }
    }
}