@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-ExtraLight.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/scss/fonts/Montserrat/static/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
  