/* Tooltips */

[ba-tooltip-title]{
    position: relative;
    cursor: pointer;
}
  
[ba-tooltip-title]:hover::after{
    content: attr(ba-tooltip-title);
    position: absolute;
    background-color: $black11;
    color: $white1;
    border-radius: 12px;
    padding: 9.6px;
    width: max-content;
    font-size: 9.6px;
    z-index: 1;
    max-width: 200px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 1px 1px 4px 0px $rgba17;
}

[ba-tooltip-position="top"]::after{
    bottom: calc(100% + 5px);
}

[ba-tooltip-position="bottom"]::after{
    top: calc(100% + 5px);
}

[ba-tooltip-position="right"]::after{
    right: calc(100% + 5px);
}

[ba-tooltip-position="left"]::after{
    left: calc(100% + 5px);
}