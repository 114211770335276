/* Loaders */

.ba-loader{

    // Sizes
    &.ba-sm{
        
    }

    &.ba-md{
        
    }

    &.ba-lg{
        
    }
}