/* Lines */

.ba-hr-row,
.ba-hr-column{
    border: none;
    background-color: $light24;
    margin: 0;
    min-height: 1px;
    min-width: 1px;
}
.ba-hr-row{
    width: 100%; 
}
.ba-hr-column{
    height: 100%;
}