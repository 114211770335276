/* Box Shadow */

// Mixin
@mixin box-shadow-hover(){
    &:hover{
        transition: .4s;
        box-shadow: 0px 4px 8px -4px $rgba13, 
                    0px 16px 24px $rgba13 !important;
    }
}

.ba-shadow-1{
    @include box-shadow-hover;
    box-shadow: 0px 4px 6px -4px $rgba7, 
                0px 8px 8px -4px $rgba8;
}

.ba-shadow-hover-1:hover{
    transition: .4s;
    box-shadow: 0px 4px 6px -4px $rgba7, 
                0px 8px 8px -4px $rgba8 !important;
}

.ba-shadow-2{
    @include box-shadow-hover;
    box-shadow: 0px 6px 8px -6px $rgba7, 
                0px 8px 16px -6px $rgba8;
}

.ba-shadow-hover-2:hover{
    transition: .4s;
    box-shadow: 0px 6px 8px -6px $rgba7, 
                0px 8px 16px -6px $rgba8 !important;
}

.ba-shadow-3{
    @include box-shadow-hover;
    box-shadow: 0px 6px 12px -6px $rgba7, 
                0px 8px 24px -4px $rgba8;
}

.ba-shadow-hover-3:hover{
    transition: .4s;
    box-shadow: 0px 6px 12px -6px $rgba7, 
                0px 8px 24px -4px $rgba8 !important;
}

.ba-shadow-4{
    @include box-shadow-hover;
    box-shadow: 0px 6px 14px -6px $rgba7, 
                0px 10px 32px -4px $rgba9;
}

.ba-shadow-hover-4:hover{
    transition: .4s;
    box-shadow: 0px 6px 14px -6px $rgba7, 
                0px 10px 32px -4px $rgba9 !important;
}

.ba-shadow-5{
    @include box-shadow-hover;
    box-shadow: 0px 8px 18px -6px $rgba7, 
                0px 12px 42px -4px $rgba7;
}

.ba-shadow-hover-5:hover{
    transition: .4s;
    box-shadow: 0px 8px 18px -6px $rgba7, 
                0px 12px 42px -4px $rgba7 !important;
}

.ba-shadow-6{
    @include box-shadow-hover;
    box-shadow: 0px 8px 22px -6px $rgba7, 
                0px 14px 64px -4px $rgba7;
}

.ba-shadow-hover-6:hover{
    transition: .4s;
    box-shadow: 0px 8px 22px -6px $rgba7, 
                0px 14px 64px -4px $rgba7 !important;
}

.ba-shadow-7{
    @include box-shadow-hover;
    box-shadow: 0px 8px 28px -6px $rgba7, 
                0px 18px 88px -4px $rgba10;
}

.ba-shadow-hover-7:hover{
    transition: .4s;
    box-shadow: 0px 8px 28px -6px $rgba7, 
                0px 18px 88px -4px $rgba10 !important;
}

.ba-shadow-8{
    @include box-shadow-hover;
    box-shadow: 0px 4px 12px -4px $rgba11, 
                0px 16px 32px $rgba12;
}

.ba-shadow-hover-8:hover{
    transition: .4s;
    box-shadow: 0px 4px 12px -4px $rgba11, 
                0px 16px 32px $rgba12 !important;
}