/* Progress */

.ba-progress{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .ba-progress-title,
    .ba-progress-data{
        color: #717D8D;
        font-size: 14px;
    }
    .ba-progress-bar{
        height: 7px;
        border-radius: 10px;
        display: flex;
        position: relative;
        border-radius: 10px;
        width: 100%;
        &::-webkit-progress-bar {
            border-radius: 10px;
            background-color: #C7D2DC59;
        }
        &::-webkit-progress-value {
            background: linear-gradient(261deg, #20E4B3 66.65%, #1DC4E9 100%);
            border-radius: 10px;
        }
        &.ba-limit-alert::-webkit-progress-bar {
            background: linear-gradient(261deg, $red1 66.65%, $red5 100%);
        }
    }
    .ba-progress-details{
        display: flex;
        justify-content: space-between;
        gap: 16px;
        position: relative;
        .ba-progress-data{
            font-size: 12px;
            &:only-child{
                right: 0;
                position: absolute;
            }
        }
    }
    &[href]:hover{
        .ba-progress-title,
        .ba-progress-data{
          color: $blue1;
          cursor: pointer;
        }
    }
}