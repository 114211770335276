/* Flex & Grid */

.ba-flex{
    display: flex;
}

.ba-grid{
    display: grid;
}

// Align Items
.ba-ai-center{
    align-items: center;
}

.ba-ai-baseline{
    align-items: baseline;
}

.ba-ai-start{
    align-items: start;
}

.ba-ai-end{
    align-items: end;
}

// Flex Wrap
.ba-f-wrap{
    flex-wrap: wrap;
}

.ba-f-nowrap{
    flex-wrap: nowrap;
}

// Flex Direction
.ba-f-column{
    flex-direction: column;
}

.ba-f-row{
    flex-direction: row;
}

// Justify Content
.ba-jc-center{
    justify-content: center;
}

.ba-jc-sb{
    justify-content: space-between;
}

.ba-jc-sa{
    justify-content: space-around;
}

.ba-jc-se{
    justify-content: space-evenly;
}

.ba-jc-fs{
    justify-content: flex-start;
}

.ba-jc-fe{
    justify-content: flex-end;
}

// Gap
.ba-gp-02{
    gap: 3.2px;
}

.ba-gp-04{
    gap: 6.4px;
}

.ba-gp-06{
    gap: 9.6px;
}

.ba-gp-08{
    gap: 12.8px;
}

.ba-gp-1{
    gap: 16px;
}

.ba-gp-2{
    gap: 32px;
}

.ba-gp-3{
    gap: 48px;
}

.ba-gp-4{
    gap: 64px;
}