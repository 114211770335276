/* Badges */

.ba-badge{
    background-color: $light14;
    color: $light15;
    font-size: 8.8px;
    padding: 3.2px 9.6px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 4.8px;
    width: max-content;
    &.ba-green::before,
    &.ba-red::before{
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
    }

    //Colors

    &.ba-green::before{
        background-color: $green1;
    }
    &.ba-red::before{
        background-color: $red1;
    }
    &.ba-bg-purple{
        background-color: $purple1;
        color: $white1;
    }
    &.ba-bg-green{
        background-color: $green2;
        color: $white1;
    }
    &.ba-bg-yellow{
        background-color: $yellow1;
        color: $white1;
    }
    &.ba-bg-red{
        background-color: $red1;
        color: $white1;
    }
    &.ba-bg-gray{
        background-color: $dark1;
        color: $white1;
    }
    &.ba-bg-orange{
        background-color: $orange1;
        color: $white1;
    }

    //Size

    &.ba-sm{
        font-size: 8.8px;
        padding: 3.2px 9.6px;
    }
    &.ba-md{
        font-size: 11px;
        padding: 4.2px 10.6px;
    }
    &.ba-lg{
        font-size: 14px;
        padding: 5.2px 11.6px;
    }

}