/* Tool Sidebar */

.ba-sidebar{
    background-color: $white1;
    border-radius: 0px 15px 15px 0px;
    padding: 22.4px 5.92px;
    width: 228px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    box-shadow: 6px 6px 13px 0px $rgba17; 
    line-height: 16px; 
    .ba-nav{
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        padding: 0;
        .ba-nav-item{
            .ba-link, 
            .ba-nav-item-expandable{
                box-sizing: border-box;
                border-radius: 4px;
                border-left: 6px solid transparent;
                font-size: 14.4px;
                color: $dark5;
                font-weight: 600;
                width: 100%;
                cursor: pointer;
                &:hover,
                &.ba-is-active{
                    transition: .6s;
                    box-shadow: 1px 2px 15px $rgba14;
                    border-left: 6px solid $blue1;
                    color: $blue1;
                    builderall-icon{
                        --builderall-icon-color: var(--blue1);
                    }
                    svg{
                        fill: $blue1;
                    }
                    .ba-title{
                        font-weight: bold;
                        font-size: 12.9px;
                        color: $blue1;
                    }
                }
                builderall-icon{
                    --builderall-icon-color: var(--dark5);
                    --builderall-icon-size: 100%;
                    &.ba-link-expandable-icon{
                        --builderall-icon-size: 11px !important;
                    }
                }
                svg,i{
                    width: 20px !important;
                    height: 20px !important;
                    font-size: 20px !important;
                    fill: $dark5;
                    &.ba-link-expandable-icon{
                        width: 10px !important;
                        height: 6px !important;
                    }
                }
                .ba-title{
                    font-weight: bold;
                    font-size: 12.9px;
                    color: $dark5;
                }
            }
            .ba-link,
            .ba-nav-item-expandable-content{
                align-items: center;
                display: grid;
                gap: 12.8px;
            }
            .ba-link{
                min-height: 34px;
                padding: 0 16px;
                grid-template-columns: 25px 1fr;
            }
            .ba-nav-item-expandable-content{
                padding: 6.4px 16px;
                grid-template-columns: 25px 1fr auto auto;
            }
            .ba-nav-subitems{
                padding: 0;
                color: $dark5;
                .ba-hr-row{
                    margin: 0 12px 8px 12px;
                    width: auto;
                }
                .ba-nav-subitem{
                    padding-bottom: 8px;
                    .ba-nav-subitem-link{
                        font-weight: 500; 
                        min-height: 34px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .ba-title{
                            padding-left: 28px;
                            font-weight: 500;
                        }
                        &:hover,
                        &.ba-is-active{
                            background-color: $blue22;
                            builderall-icon{
                                --builderall-icon-color: var(--blue1) !important;
                            }
                            .ba-title{
                                color: $blue1;
                            }
                        }
                        &:not(.ba-back):has(builderall-icon){
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            padding-left: 20px;
                            builderall-icon{
                                --builderall-icon-color: var(--dark5);
                                --builderall-icon-size: 22px;
                            }
                            .ba-title{
                                padding-left: 0;
                            }
                        }
                        &.ba-back{
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            padding: 4px 16px;
                            box-sizing: border-box;
                            builderall-icon{
                                --builderall-icon-size: 16px;
                            }
                            .ba-title{
                                padding-left: 0;
                            }
                        }
                        &.ba-current-section{
                            background-color: $light14;
                            margin: 0 12px;
                            width: auto;
                            border-radius: 6px;
                            cursor: initial;
                            .ba-title{
                                font-weight: bold;
                                padding: 8px 16px;
                                color: $dark5;
                            }
                        }
                    }
                }
            }
            .ba-nav-item-expandable{
                display: block;
                .ba-nav-subitems{
                    opacity: 0;
                    max-height: 0;
                    overflow: hidden;
                    transition: opacity 0.4s ease, max-height 0.4s ease, padding 0.4s ease;
                }
            }
            .ba-nav-item-input-status {
                display: none;
                &:checked ~ .ba-nav-item-expandable{
                    transition: .6s;
                    box-shadow: 1px 2px 15px $rgba14;
                    border-left: 6px solid $blue1;
                    color: $blue1;
                    builderall-icon{
                        --builderall-icon-color: var(--blue1);
                    }
                    svg{
                        fill: $blue1;
                    }
                    .ba-title{
                        color: $dark5;
                    }
                    .ba-nav-item-expandable-content{
                        .ba-title{
                            color: $blue1;
                        }
                    }
                }
                &:checked ~ .ba-nav-item-expandable .ba-nav-subitems{
                    opacity: 1;
                    max-height: 1000px;
                }
                &:checked ~ .ba-nav-item-expandable .ba-link-expandable-icon{
                    transition: .4s;
                    transform: rotate(180deg); 
                }
                &:not(:checked) ~ .ba-nav-item-expandable .ba-link-expandable-icon{
                    transition: .4s;
                    transform: rotate(0deg);
                }
            }
        }
    }
    .ba-bottom{
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 12.8px;
        .ba-bottom-list{
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            li{
                .ba-flutuant-link{
                    font-size: 14.4px;
                    display: flex;
                    align-items: center;
                    gap: 6.4px;
                    &:hover{
                        .ba-icon{
                            svg{
                                transition: .4s;
                                fill: $dark3 !important;
                            }
                        }
                        .ba-text{
                            transition: .4s;
                            color: $dark3 !important;
                        }
                    }
                    .ba-icon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        builderall-icon{
                            --builderall-icon-color: var(--dark5);
                            --builderall-icon-size: 20px;
                        }
                        svg,i{
                            fill: $dark5;
                            width: 20px !important;
                            height: 20px !important;
                            font-size: 20px !important;
                        }
                    }
                    .ba-text{
                        color: $dark5;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}