/* Banners */

.ba-banner{
    background: linear-gradient(92.25deg, $rgba16 31.46%, $rgba15 85.86%);
    padding: 17.6px 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 195px;
    position: relative;
    box-sizing: border-box;
    transition: height 1s ease;
    .ba-span{
        color: $white1;
    }
    .ba-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 0;
        .ba-image{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            object-fit:cover;
        }
        .ba-header-left{
            display: flex;
            align-items: center;
            gap: 8px;
            .ba-icon{
                width: 45px;
                height: 45px;
                background-color: $white1;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    max-width: 45px;
                    max-height: 45px;
                    border-radius: 50%;
                    fill: $blue1;
                }
            }
            .ba-span{
                font-size: 25.6px;
                font-weight: bold;
            }
        }
        .ba-label{
            background-color: $black23;
            width: 25px;
            height: 25px;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            color: $dark4;
            background-image: url(https://cheetah.builderall.com/franquias/2/7291108/editor-html/11332973.svg);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .ba-input-status{
        display: none;
        &:checked ~ .ba-header > .ba-label{
            transform: rotate(180deg);
            transition: .4s;
        }
    }
    .ba-tool-description{
        z-index: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        animation-name: showOpacity;
        animation-duration: .4s;
        opacity: 1;
        max-width: 1000px;
        .ba-span:first-child{
            font-size: 24px;
            font-weight: bold;
        }
        .ba-span:last-child{
            font-size: 16px;
            font-weight: 100;
        }
    }
}
.ba-banner:has(.ba-input-status:checked){
    height: 106px;
    .ba-tool-description{
        animation-name: hideOpacity;
        animation-duration: .4s;
        opacity: 0;
    }
    .ba-app-wrapper{
        margin-top: 65.6px !important;
    }
}

@keyframes hideOpacity {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes showOpacity {
    from {opacity: 0;}
    to {opacity: 1;}
}