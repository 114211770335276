/* Cards */

.ba-card{
    background-color: $white1;
    padding: 18px;
    box-shadow: 0px 4px 6px -4px $rgba7, 0px 8px 8px -4px $rgba8;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    box-sizing: border-box;
    .ba-image{
        border-radius: 20px;
        width: 100%;
    }
    .ba-top{
        width: 100%;
        gap: 16px;
        &:not(:has(.ba-subtitle)){
            .ba-title{
                border-bottom: 1px solid $light1; 
            }
        }
        &:has(.ba-subtitle){
            .ba-subtitle{
                padding-bottom: 6px;
                color: $black21;
                font-size: 13px;
                font-weight: 500;
                &:last-child{
                    border-bottom: 1px solid $light1;
                }
            }
        }
        .ba-title{
            color: $dark5;
            display: flex;
            padding-bottom: 10px;
        }
        &:has(.ba-box-items){
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: start;
        }
    }
    .ba-title{
        font-size: 16px;
        font-weight: 600;
    }
    .ba-description{
        font-size: 12.8px;
        color: $black13;
    }
    .ba-hr-column{
        min-height: 50px;
    }

    // Options
    &.ba-card-grid{
        gap: 6px !important;
        height: 235px;
        .ba-top{
            builderall-icon{
                --builderall-icon-color: var(--light15) !important;
            }
            .ba-title{
                height: 28px;
            }
        }
        .ba-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            width: 100%;
            height: 100%;
            .ba-card{
                width: 100% !important;
                min-width: min-content;
            }
            .ba-hr-column{
                height: 140px !important;
            }
        }
    }

    &.ba-row{
        display: grid;
        grid-template-columns: auto auto;
        width: 630px;
        .ba-content{
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &.ba-blue{
        background-color: $blue17;
        border: 1px solid $blue18;
    }

    &.ba-no-shadow{
        box-shadow: none;
    }

    &.ba-no-padding{
        padding: 0;
        .ba-image{
            border-radius: 20px 20px 0 0;
        }
        :is(.ba-title,.ba-description){
            margin: 0 12.8px;
        }
        .ba-btn{
            margin: 0 12.8px 12.8px 12.8px;
        }
    }

    &.ba-statistics{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 9.6px;
        min-width: 250px;
        min-height: 146px;
        box-shadow: none;
        &:hover{
            transition: .2s;
            box-shadow: 1px 2px 15px 0px $rgba14;
        }
        .ba-top{
            display: flex;
            align-items: center;
            gap: 6.4px;
            .ba-icon{
                background-color: $light20;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                builderall-icon{
                    --builderall-icon-color: var(--blue1);
                    --builderall-icon-size: 18px;
                }
                svg{
                    fill: $blue1;
                    width: 15px;
                    height: 15px;
                }
            }
            .ba-title{
                border-bottom: none;
                font-size: 12px;
                font-weight: 400;
                color: $black13;
                height: auto;
            }
        }
        .ba-content{
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
            .ba-data{
                color: $dark5;
                font-size: 24px;
                font-weight: 700;
            }
            .ba-icon{
                svg{
                    width: 18px;
                    height: 18px;
                }
            }
            &.ba-green{
                .ba-data{
                    color: $green19;
                }
                .ba-icon{
                    builderall-icon{
                        --builderall-icon-color: var(--green19);
                    }
                    svg{
                        fill: $green19;
                    }
                }
            }
            &.ba-red{
                .ba-data{
                    color: $red1;
                }
                .ba-icon{
                    builderall-icon{
                        --builderall-icon-color: var(--red1);
                    }
                    svg{
                        fill: $red1;
                    }
                }
            }
        }
        .ba-bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            width: 100%;
            min-height: 16px;
            .ba-text{
                color: $black13;
                font-size: 12px;
                font-weight: 500;
            }
            .ba-number{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 4px;
                padding: 1px 4px;
                max-height: 21px;
                .ba-number-data{
                    font-size: 12px;
                    font-weight: 600;
                }
                &.ba-green{
                    background-color: $green14;
                    .ba-number-data{
                        color: $green20 !important;
                    }
                }
                &.ba-red{
                    background-color: $red14;
                    .ba-number-data{
                        color: $red15 !important;
                    }
                }
                &.ba-yellow{
                    background-color: $yellow10;
                    .ba-number-data{
                        color: $orange1 !important;
                    }
                }
            }
        }
    }

    // Sizes
    &.ba-100{
        width: 100px;
    }

    &.ba-200{
        width: 200px;
    }

    &.ba-300{
        width: 300px;
    }

    &.ba-400{
        width: 400px;
    }

    &.ba-500{
        width: 500px;
    }
}