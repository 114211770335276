/* Footer */

.ba-footer{
    padding-bottom: 16px;

    // legacy (before web component)
    .ba-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        .ba-link{
            color: $dark5;
            font-size: 14.4px;
            cursor: pointer;
        }
        .ba-blue{
            color: $blue1;
        }
        hr{
            height: 14px;
            border-radius: 10px;
        }
    }
}