/* Paginations */

.ba-pagination{
    .ba-list{
        display: flex;
        gap: 9.6px;
        color: $black13;
        align-items: center;
        padding: 0;
        .ba-nav-item{
            color: $black13;
            .ba-back{
                margin-right: 16px;
            }
            .ba-link{
                font-size: 14.4px;
                padding: 9.6px 16px;
                border-radius: 8px;
                &:hover,&.ba-is-active{
                    background-color: $blue14;
                    color: $blue1;
                }
                &.ba-is-active{
                    font-weight: 600;
                }
            }
            .ba-next{
                margin-left: 16px;
            }
            :is(.ba-back,.ba-next){
                display: flex;
                align-items: center;
                gap: 9.6px;
                font-weight: bold;
                .ba-icon{
                    display: flex;
                    builderall-icon{
                        --builderall-icon-size: 14px;
                        --builderall-icon-color: var(--black13);
                    }
                    svg{
                        fill: $black13;
                    }
                }
                .ba-text{
                    color: $black13;
                }
            }
        }
    }

    // Sizes
    &.ba-sm{
        .ba-list{
            .ba-nav-item{
                :is(.ba-link,.ba-text){
                    font-size: 10.4px;
                }
                :is(.ba-back,.ba-next){
                    .ba-icon{
                        svg{
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }
    }

    &.ba-md{
        .ba-list{
            .ba-nav-item{
                :is(.ba-link,.ba-text){
                    font-size: 15.2px;
                }
                :is(.ba-back,.ba-next){
                    .ba-icon{
                        builderall-icon{
                            --builderall-icon-size: 18px;
                        }
                        svg{
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }

    &.ba-lg{
        .ba-list{
            .ba-nav-item{
                :is(.ba-link,.ba-text){
                    font-size: 20px;
                }
                :is(.ba-back,.ba-next){
                    .ba-icon{
                        builderall-icon{
                            --builderall-icon-size: 22px;
                        }
                        svg{
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }
}