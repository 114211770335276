/* Toast */

.ba-toast{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    font-size: 13.6px;
    gap: 10px;
    background-color: $white1;
    span{
        margin-left: 0;
    }
    .ba-toast-alert{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        border-radius: 4px;
        padding: 0px 8px;
        height: 38px;
    }
    .ba-description{
        color: $dark5;
        font-size: 12px;
        font-weight: 400;
        padding: 8px;
    }
    .ba-btn{
        max-width: 161px;
        max-height: 31px;
        gap: 10px;
        margin: 8px;
    }
    &.ba-info{
        .ba-toast-alert{
            background-color: #E5F2FF;
            border: 1px solid #66B3FF;
        }
        .ba-left{
            .ba-icon{
                background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='36' height='36' rx='8' fill='%23004D99'/%3E%3Cpath d='M18 6C11.376 6 6 11.376 6 18C6 24.624 11.376 30 18 30C24.624 30 30 24.624 30 18C30 11.376 24.624 6 18 6ZM19.2 24H16.8V16.8H19.2V24ZM19.2 14.4H16.8V12H19.2V14.4Z' fill='%23FBFCFD'/%3E%3C/svg%3E%0A");
            }
        }
        .ba-close-btn{
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.01763 0C4.07603 0 0.0703125 4.0293 0.0703125 9C0.0703125 13.9707 4.07603 18 9.01763 18C13.9592 18 17.9649 13.9707 17.9649 9C17.9649 4.0293 13.9592 0 9.01763 0ZM12.4002 11.4475C12.6623 11.7112 12.6623 12.1383 12.4002 12.402C12.2691 12.5343 12.0973 12.6 11.9255 12.6C11.7537 12.6 11.5819 12.5343 11.4509 12.4024L9.01763 9.95445L6.58441 12.402C6.45333 12.5343 6.28154 12.6 6.10975 12.6C5.93796 12.6 5.76617 12.5343 5.6351 12.4024C5.37294 12.1388 5.37294 11.7117 5.6351 11.448L8.06877 9L5.63554 6.55245C5.37339 6.28875 5.37339 5.8617 5.63554 5.598C5.8977 5.3343 6.32225 5.3343 6.58441 5.598L9.01763 8.04555L11.4509 5.598C11.713 5.3343 12.1376 5.3343 12.3997 5.598C12.6619 5.8617 12.6619 6.28875 12.3997 6.55245L9.96649 9L12.4002 11.4475Z' fill='%23004D99'/%3E%3C/svg%3E%0A");
        }
    }
    &.ba-alert{
        .ba-toast-alert{
            background-color: #FFF6E5;
            border: 1px solid #FFC35C;
        }
        .ba-left{
            .ba-icon{
                background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='36' height='36' rx='8' fill='%23FFC35C'/%3E%3Cg clip-path='url(%23clip0_895_1007)'%3E%3Cpath d='M8.73041 27H27.2604C28.0304 27 28.5104 26.17 28.1304 25.5L18.8604 9.5C18.4704 8.83 17.5104 8.83 17.1304 9.5L7.86041 25.5C7.48041 26.17 7.96041 27 8.73041 27ZM19.0004 24H17.0004V22H19.0004V24ZM18.0004 20C17.4504 20 17.0004 19.55 17.0004 19V17C17.0004 16.45 17.4504 16 18.0004 16C18.5504 16 19.0004 16.45 19.0004 17V19C19.0004 19.55 18.5504 20 18.0004 20Z' fill='%23FBFCFD'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_895_1007'%3E%3Crect width='24' height='24' fill='white' transform='translate(6 6)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }
        }
        .ba-close-btn{
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.01763 0C4.07603 0 0.0703125 4.0293 0.0703125 9C0.0703125 13.9707 4.07603 18 9.01763 18C13.9592 18 17.9649 13.9707 17.9649 9C17.9649 4.0293 13.9592 0 9.01763 0ZM12.4002 11.4475C12.6623 11.7112 12.6623 12.1383 12.4002 12.402C12.2691 12.5343 12.0973 12.6 11.9255 12.6C11.7537 12.6 11.5819 12.5343 11.4509 12.4024L9.01763 9.95445L6.58441 12.402C6.45333 12.5343 6.28154 12.6 6.10975 12.6C5.93796 12.6 5.76617 12.5343 5.6351 12.4024C5.37294 12.1388 5.37294 11.7117 5.6351 11.448L8.06877 9L5.63554 6.55245C5.37339 6.28875 5.37339 5.8617 5.63554 5.598C5.8977 5.3343 6.32225 5.3343 6.58441 5.598L9.01763 8.04555L11.4509 5.598C11.713 5.3343 12.1376 5.3343 12.3997 5.598C12.6619 5.8617 12.6619 6.28875 12.3997 6.55245L9.96649 9L12.4002 11.4475Z' fill='%23FFC35C'/%3E%3C/svg%3E");
        }
    }
    &.ba-error{
        .ba-toast-alert{
            background-color: #FFEBEB;
            border: 1px solid #FF8585;
        }
        .ba-left{
            .ba-icon{
                background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='36' height='36' rx='8' fill='%23FF5C5C'/%3E%3Cpath d='M18.0001 6C11.4113 6 6.07031 11.3724 6.07031 18C6.07031 24.6276 11.4113 30 18.0001 30C24.5889 30 29.9298 24.6276 29.9298 18C29.9298 11.3724 24.5889 6 18.0001 6ZM22.5101 21.2634C22.8597 21.615 22.8597 22.1844 22.5101 22.536C22.3353 22.7124 22.1063 22.8 21.8772 22.8C21.6482 22.8 21.4191 22.7124 21.2444 22.5366L18.0001 19.2726L14.7558 22.536C14.581 22.7124 14.3519 22.8 14.1229 22.8C13.8938 22.8 13.6648 22.7124 13.49 22.5366C13.1405 22.185 13.1405 21.6156 13.49 21.264L16.7349 18L13.4906 14.7366C13.1411 14.385 13.1411 13.8156 13.4906 13.464C13.8402 13.1124 14.4062 13.1124 14.7558 13.464L18.0001 16.7274L21.2444 13.464C21.5939 13.1124 22.16 13.1124 22.5095 13.464C22.8591 13.8156 22.8591 14.385 22.5095 14.7366L19.2652 18L22.5101 21.2634Z' fill='%23FBFCFD'/%3E%3C/svg%3E ");
            }
        }
        .ba-close-btn{
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.01763 0C4.07603 0 0.0703125 4.0293 0.0703125 9C0.0703125 13.9707 4.07603 18 9.01763 18C13.9592 18 17.9649 13.9707 17.9649 9C17.9649 4.0293 13.9592 0 9.01763 0ZM12.4002 11.4475C12.6623 11.7112 12.6623 12.1383 12.4002 12.402C12.2691 12.5343 12.0973 12.6 11.9255 12.6C11.7537 12.6 11.5819 12.5343 11.4509 12.4024L9.01763 9.95445L6.58441 12.402C6.45333 12.5343 6.28154 12.6 6.10975 12.6C5.93796 12.6 5.76617 12.5343 5.6351 12.4024C5.37294 12.1388 5.37294 11.7117 5.6351 11.448L8.06877 9L5.63554 6.55245C5.37339 6.28875 5.37339 5.8617 5.63554 5.598C5.8977 5.3343 6.32225 5.3343 6.58441 5.598L9.01763 8.04555L11.4509 5.598C11.713 5.3343 12.1376 5.3343 12.3997 5.598C12.6619 5.8617 12.6619 6.28875 12.3997 6.55245L9.96649 9L12.4002 11.4475Z' fill='%23FF5C5C'/%3E%3C/svg%3E ");
        }
    }
    &.ba-success{
        .ba-toast-alert{
            background-color: #E8FCF7;
            border: 1px solid #7BEFD2;
        }
        .ba-left{
            .ba-icon{
                background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='36' height='36' rx='8' fill='%23118D6E'/%3E%3Cpath d='M28.11 11.5626L17.136 22.5366C16.9608 22.7124 16.7304 22.8 16.5 22.8C16.2696 22.8 16.0392 22.7124 15.8634 22.5366L12.8634 19.5366C12.5118 19.185 12.5118 18.6156 12.8634 18.264C13.215 17.9124 13.7844 17.9124 14.136 18.264L16.4994 20.6274L27.0174 10.1094C24.8166 7.5966 21.5946 6 18 6C11.3832 6 6 11.3832 6 18C6 24.6168 11.3832 30 18 30C24.6168 30 30 24.6168 30 18C30 15.6306 29.3004 13.425 28.11 11.5626Z' fill='%23FBFCFD'/%3E%3C/svg%3E%0A");
            }
        }
        .ba-close-btn{
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.01763 0C4.07603 0 0.0703125 4.0293 0.0703125 9C0.0703125 13.9707 4.07603 18 9.01763 18C13.9592 18 17.9649 13.9707 17.9649 9C17.9649 4.0293 13.9592 0 9.01763 0ZM12.4002 11.4475C12.6623 11.7112 12.6623 12.1383 12.4002 12.402C12.2691 12.5343 12.0973 12.6 11.9255 12.6C11.7537 12.6 11.5819 12.5343 11.4509 12.4024L9.01763 9.95445L6.58441 12.402C6.45333 12.5343 6.28154 12.6 6.10975 12.6C5.93796 12.6 5.76617 12.5343 5.6351 12.4024C5.37294 12.1388 5.37294 11.7117 5.6351 11.448L8.06877 9L5.63554 6.55245C5.37339 6.28875 5.37339 5.8617 5.63554 5.598C5.8977 5.3343 6.32225 5.3343 6.58441 5.598L9.01763 8.04555L11.4509 5.598C11.713 5.3343 12.1376 5.3343 12.3997 5.598C12.6619 5.8617 12.6619 6.28875 12.3997 6.55245L9.96649 9L12.4002 11.4475Z' fill='%23118D6E'/%3E%3C/svg%3E ");
        }
    }
    .ba-left{
        display: flex;
        align-items: center;
        gap: 6.4px;
        .ba-text{
            color: $dark5;
        }
        .ba-icon{
            width: 26px;
            height: 26px;
        }
    }
    .ba-close-btn{
        width: 14px;
        height: 14px;
        cursor: pointer;

    }
    :is(.ba-icon,.ba-close-btn){
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
    }
}