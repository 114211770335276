/* Selects */

.ba-select{
    background-color: $light22;
    padding: 6.4px 12.8px;
    font-size: 12.8px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: $dark6;
    font-weight: 600;
    min-height: 35px;

    // Sizes
    &.ba-sm{
        padding: 3.2px 9.6px;
        font-size: 9.6px;
    }

    &.ba-md{
        padding: 6.4px 12.8px;
        font-size: 12.8px;
    }

    &.ba-lg{
        padding: 12.8px 16px;
        font-size: 16px;
    }
}