/* Tables */

.ba-table{
    border-collapse: separate;
    border-spacing: 0 9.6px;
    &:has(.ba-no-records-found){
        min-height: 100px;
    }
    thead{
        tr{
            background-color: $light14;
            border-radius: 6px;
            th{
                padding: 9.6px 16px;
                color: $dark5;;
                font-weight: 600;
                &:first-child{
                    border-radius: 6px 0 0 6px;
                }
                &:last-child{
                    border-radius: 0 6px 6px 0;
                }
            }
        }
    }
    tbody{
        tr{
            border-radius: 6px;
            position: relative;
            td{
                padding: 9.6px 16px;
                // border-top: 1px solid $light19;
                // border-bottom: 1px solid $light19;
                color: $dark5;
                position: relative;
                &:first-child{
                    // border-left: 1px solid $light19;
                    border-radius: 6px 0 0 6px;
                }
                &:last-child{
                    // border-right: 1px solid $light19;
                    border-radius: 0 6px 6px 0;
                }
                &.ba-double{
                    :is(.ba-title,.ba-subtitle){
                        color: $dark5;
                        font-size: inherit;
                    }
                    .ba-title{
                        font-weight: 700;
                    }
                }
                &.ba-no-records-found{
                    width: 100%;
                    text-align: center;
                    box-sizing: border-box;
                    position: absolute;
                }
            }
            &:hover{
                box-shadow: 0 0 3px $rgba17;
                transition: box-shadow .2s ease;
            }
        }
    }

    // Sizes
    &.ba-sm{
        thead{
            tr{
                th{
                    font-size: 11.2px;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 12.8px;
                }
            }
        }
    }

    &.ba-md{
        thead{
            tr{
                th{
                    font-size: 14.4px;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 16px;
                }
            }
        }
    }

    &.ba-lg{
        thead{
            tr{
                th{
                    font-size: 17.6px;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: 19.2px;
                }
            }
        }
    }

    // Lines
    &.ba-lines{
        tbody{
            tr{
                td:not(:nth-last-child(2),:last-child):after{
                    content: "";
                    position: absolute;
                    top: 25%;
                    right: 0;
                    height: 50%;
                    border-right: 1px solid $light1; 
                }
            }
        }
    }
    &.ba-lines-all{
        tbody{
            tr{
                td:not(:last-child):after{
                    content: "";
                    position: absolute;
                    top: 25%;
                    right: 0;
                    height: 50%;
                    border-right: 1px solid $light1; 
                }
            }
        }
    }
}

.ba-border-table{
    border-collapse: collapse;
    width: 90%;
    :is(th, td, tr):not(.ba-table tr, .ba-table td, .ba-table th){
        border: 1px solid $black18;
        padding: 16px;
    }
}