@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-ExtraLight.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src: url("/scss/fonts/Mulish/static/Mulish-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}