/* Tabs */

.ba-box-tabs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    .ba-tab{
        background-color: $light7;
        font-size: 12.8px;
        color: $black10;
        border-radius: 4px;
        padding: 6.4px 16px;
        cursor: pointer;
        &:hover,
        &.ba-is-active{
            color: $blue19;
            background-color: transparent;
            border-top: 1.5px solid $blue19;
            border-radius: 0;
        }
        &.ba-is-active{
            font-weight: 600;
        }
    }
}