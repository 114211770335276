@import './helpers/all';
@import './components/all';
@import './fonts/all';

*{
    font-family: $montserrat;
}

html,body{
    overflow: hidden;
}

body{
    margin: 0;
    padding: 0;
    background: $blue18;
    color: $black21; 
}

a{
    text-decoration: none;
    color: inherit;
}

ul{
    list-style: none;
}

builderall-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ba-app-content{
    position: absolute;
    background: $blue18;
    width: calc(100% - 54px);
    height: calc(100% - 80px);
    overflow-y: auto;
    transition: filter 0.15 linear;
    &.has-pay-now {
        height: calc(100% - 164px) !important;
    }
    .ba-app-wrapper{
        display: grid;
        grid-template-columns: auto 1fr;
        z-index: 1;
        position: absolute;
        width: 100%;
        margin-top: -24px;
        transition: margin-top 1s;
        padding-bottom: 14px;
        padding-right: 16px;
        box-sizing: border-box;
        .ba-sidebar{
            grid-row: 1/3;
        }
    }
    .ba-container{
        max-width: 1100px;
        margin: 0 auto;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .ba-app-content{
        width: 100%;
    }
}