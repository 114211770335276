/* Inputs */

// Normal
.ba-input{
    background-color: $light14;
    border: 2px solid $light14;
    outline: 2px solid $light14;
    outline: none;
    color: $light15;
    padding: 9.6px 12.8px;
    border-radius: 6px;
    &::placeholder{
        color: $light15;
    }
    &:focus{
        transition: .2s;
        border: 2px solid $blue4;
        background-color: $light8;
        outline: 2px solid $light18;
    }
    &.ba-error{
        transition: .2s;
        border: 2px solid $red1;
        background-color: $light8;
        outline: 2px solid $red14;
        color: $red1;
        &::placeholder{
            color: $red1;
        }
    }
    &:disabled{
        cursor: not-allowed;
    }

    // Sizes
    &.ba-sm{
        padding: 3.6px 6.8px;
        font-size: 12.8px;
    }
    &.ba-md{
        padding: 9.6px 12.8px;
        font-size: 14.4px;
    }
    &.ba-lg{
        padding: 12.6px 15.8px;
        font-size: 16px;
    }
}

// Input with Icon
.ba-box-input-icon{
    position: relative;
    .ba-input{
        width: 100%;
        box-sizing: border-box;
    }
    div{
        display: flex;
        align-items: center;
        gap: 1px;
        background-color: $light14;
        position: absolute;
        right: 2px;
        top: 2px;
        height: 90%;
        display: flex;
        border-radius: 6px;
        .ba-button{
            background-color: transparent;
            border: none;
            cursor: pointer;
            builderall-icon{
                --builderall-icon-size: 16px;
                --builderall-icon-color: var(--blue1);
            }
        }
    }
    .ba-input:focus ~ div{
        transition: .2s;
        background-color: $light8;
    }

    // Sizes
    &.ba-sm{
        .ba-input{
            padding: 3.6px 6.8px;
            font-size: 12.8px;
        }
        div{
            height: 80%;
            .ba-button{
                builderall-icon {
                    --builderall-icon-size: 12px;
                }
            }
        }
    }
    &.ba-md{
        .ba-input{
            padding: 9.6px 12.8px;
            font-size: 14.4px;
        }
    }
    &.ba-lg{
        .ba-input{
            padding: 12.6px 15.8px;
            font-size: 16px;
        }
        div{
            .ba-button{
                builderall-icon {
                    --builderall-icon-size: 20px;
                }
            }
        }
    }
}

// Input with element
.ba-box-input-with-element{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 6px;
    align-items: center;
    background-color: $light14;
    border: 2px solid $light14;
    outline: 2px solid $light14;
    outline: none;
    border-radius: 6px;
    .ba-bi-element{
        font-size: 14px;
    }
    .ba-bi-box-input{
        position: relative;
        height: 100%;
        &::before{
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #D5DEE9;
        }
        input{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
        div{
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            display: flex;
            background-color: $light14;
            border-radius: 6px;
            .ba-button{
                background-color: transparent;
                border: none;
                cursor: pointer;
                builderall-icon{
                    --builderall-icon-size: 16px;
                    --builderall-icon-color: var(--blue1);
                }
            }
        }
    }
    .ba-bi-element,input{
        border: none;
        outline: none;
        background: transparent;
        color: $light15;
        padding: 9.6px 12.8px;
        &::placeholder{
            color: $light15;
        }
    }
    &:has(.ba-bi-element:focus):not(.ba-error), &:has(input:focus):not(.ba-error){
        transition: .2s;
        border: 2px solid $blue4;
        background-color: $light8;
        outline: 2px solid $light18;
        .ba-bi-box-input{
            div{
                transition: .2s;
                background-color: $light8;
            }
        }
    }
    &.ba-error{
        transition: .2s;
        border: 2px solid $red1;
        background-color: $light8;
        outline: 2px solid $red14;
        .ba-bi-element, input{
            color: $red1;
        }
        .separator{
            background-color: $red1;
        }
        input::placeholder{
            color: $red1;
        }
    }
    &:disabled{
        .ba-bi-element,input{
            cursor: not-allowed;
        }
    }

    // Sizes
    &.ba-sm{
        .ba-bi-element,input{
            padding: 3.6px 6.8px;
            font-size: 12.8px;
        }
        .ba-bi-box-input{
            .ba-button{
                builderall-icon{
                    --builderall-icon-size: 12px;
                }
            }
        }
    }
    &.ba-md{
        .ba-bi-element,input{
            padding: 9.6px 12.8px;
            font-size: 14.4px;
        }
    }
    &.ba-lg{
        .ba-bi-element,input{
            padding: 12.6px 15.8px;
            font-size: 16px;
        }
        .ba-bi-box-input{
            .ba-button{
                builderall-icon{
                    --builderall-icon-size: 20px;
                }
            }
        }
    }
}

// Search
.ba-box-search-input{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1.6px;
    background-color: $light14;
    border: 2px solid $light14;
    outline: 2px solid $light14;
    padding: 9.6px 12.8px;
    border-radius: 6px;
    &:focus-within{
        transition: .2s;
        border: 2px solid $blue4;
        background-color: $light8;
        outline: 2px solid $light18;
    }
    .ba-input[type="search"]{
        background-color: transparent;
        border: none;
        outline: none;
        color: $light15;
        padding: 0;
        border-radius: 0;
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
        &::placeholder{
            color: $light15;
        }
    }
    .ba-button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        builderall-icon{
            --builderall-icon-size: 20px;
            --builderall-icon-color: var(--light16);
        }
        svg{
            fill: $light16;
            width: 16px;
            height: 16px;
        }
        &:hover{
            svg{
                transition: .4s;
                fill: $blue1;
            }
        }
    }
    &.ba-error{
        transition: .2s;
        border: 2px solid $red1;
        background-color: $light8;
        outline: 2px solid $red14;
        .ba-input[type="search"]{
            color: $red1;
            &::placeholder{
                color: $red1;
            }
        }
        .ba-button{
            builderall-icon{
                --builderall-icon-color: var(--red1);
            }
            svg{
                fill: $red1;
            }
        }
    }
    &.ba-disabled{
        transition: .2s;
        background-color: $light14;
        border: 2px solid $light14;
        outline: 1px solid $light17;
        .ba-button{
            &:disabled{
                cursor: not-allowed;
            }
            svg{
                fill: $light16;
            }
        }
    }
    &.ba-white{
        background-color: $white1;
        border: none;
        outline: none;
        padding: 9.6px;
    }

    // Sizes
    &.ba-sm{
        padding: 3.6px 6.8px;
        .ba-input[type="search"]{
            font-size: 12.8px;
        }
        .ba-button{
            builderall-icon{
                --builderall-icon-size: 16px;
            }
        }
    }
    &.ba-md{
        padding: 9.6px 12.8px;
        .ba-input[type="search"]{
            font-size: 14.4px;
        }
        .ba-button{
            builderall-icon{
                --builderall-icon-size: 19px;
            }
        }
    }
    &.ba-lg{
        padding: 12.6px 15.8px;
        .ba-input[type="search"]{
            font-size: 16px;
        }
        .ba-button{
            builderall-icon{
                --builderall-icon-size: 22px;
            }
        }
    }
}

// Complete with validation
.ba-box-input{
    display: flex;
    flex-direction: column;
    gap: 3.2px;
    .ba-info{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ba-info-left{
            display: flex;
            align-items: center;
            gap: 3.2px;
            .ba-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                builderall-icon{
                    --builderall-icon-size: 14px;
                    --builderall-icon-color: var(--black19);
                }
                svg{
                    width: 11px;
                    height: 11px;
                    fill: $black19;
                }
            }
            .ba-label{
                font-size: 12.8px;
            }
        }
        .ba-optional{
            float: right;
            font-size: 9.6px;
            color: $black18;
        }
    }
    .ba-validation-text{
        font-size: 9.6px;
        color: $black18;
    }
    &.ba-error{
        .ba-input{
            transition: .2s;
            border: 2px solid $red1;
            background-color: $light8;
            outline: 2px solid $red14;
            color: $red1;
            &::placeholder{
                color: $red1;
            }
        }
    }

    // Sizes
    &.ba-sm{
        .ba-info .ba-info-left .ba-label,
        .ba-info .ba-optional{
            font-size: 9.6px;
        }
        .ba-info{
            .ba-info-left{
                .ba-icon{
                    builderall-icon{
                        --builderall-icon-size: 11px;
                    }
                }
            }
            .ba-optional{
                font-size: 9.6px;
            }
        }
        .ba-input{
            padding: 3.6px 6.8px;
            font-size: 12.8px;
        }
    }
    &.ba-md{
        .ba-info .ba-info-left .ba-label,
        .ba-info .ba-optional{
            font-size: 12.8px;
        }
        .ba-info{
            .ba-info-left{
                .ba-icon{
                    builderall-icon{
                        --builderall-icon-size: 13px;
                    }
                }
            }
        }
        .ba-input{
            padding: 9.6px 12.8px;
            font-size: 14.4px;
        }
    }
    &.ba-lg{
        .ba-info .ba-info-left .ba-label,
        .ba-info .ba-optional{
            font-size: 16px;
        }
        .ba-info{
            .ba-info-left{
                .ba-icon{
                    builderall-icon{
                        --builderall-icon-size: 15px;
                    }
                }
            }
        }
        .ba-input{
            padding: 12.6px 15.8px;
            font-size: 16px;
        }
    }
}

// Textarea
.ba-textarea{
    font-size: 14px;
    font-weight: 400;
    background: $light14;
    border: 2px solid $light14;
    outline: 2px solid $light14;
    color: $light15;
    padding: 9.6px 12.8px;
    border-radius: 6px;

    &::placeholder{
        color: $light15;
    }

    &:focus{
        transition: .2s;
        border: 2px solid $blue4;
        background-color: $light8;
        outline: 2px solid $light18;
    }
    &.ba-error{
        transition: .2s;
        border: 2px solid $red1;
        background-color: $light8;
        outline: 2px solid $red14;
        color: $red1;
        &::placeholder{
            color: $red1;
        }
    }
    &:disabled{
        cursor: not-allowed;
    }
}

// Global Settings
.ba-sm{
    .ba-validation-text{
        font-size: 9.6px;
    }
}
.ba-md{
    .ba-validation-text{
        font-size: 12.8px;
    }
}
.ba-lg{
    .ba-validation-text{
        font-size: 16px;
    }
}