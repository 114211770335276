/* Buttons */

// Mixins
@mixin useIconWhite(){
    builderall-icon{
        --builderall-icon-color: var(--white1);
    }
}

// Default button
.ba-btn{
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9.6px;
    padding: 6.4px 12.8px;
    font-size: 12px;
    
    &:hover{
        text-decoration: none;
    }
    
    builderall-icon{
        transition: .4s;
    }

    // Sizes
    &.ba-sm{
        font-size: 12px;
        builderall-icon{
            --builderall-icon-size: 17px;
        }
        &:not(.ba-rounded){
            padding: 6.4px 12.8px;
        }
        &.ba-rounded{
            width: 30px;
            height: 30px;
        }
    }

    &.ba-md{
        font-size: 12.8px;
        builderall-icon{
            --builderall-icon-size: 22px;
        }
        &:not(.ba-rounded){
            padding: 9.6px 19.2px;
        }
        &.ba-rounded{
            width: 38px;
            height: 38px;
        }
    }

    &.ba-lg{
        font-size: 13.6px;
        builderall-icon{
            --builderall-icon-size: 26px;
        }
        &:not(.ba-rounded){
            padding: 12.8px 25.6px;
        }
        &.ba-rounded{
            width: 46px;
            height: 46px;
        }
    }

    &.ba-xl{
        font-size: 14.4px;
        builderall-icon{
            --builderall-icon-size: 30px;
        }
        &:not(.ba-rounded){
            padding: 16px 32px;
        }
        &.ba-rounded{
            width: 54px;
            height: 54px;
        }
    }

    // Colors
    &.ba-blue{
        background-color: $blue1;
        color: $white1;
        @include useIconWhite;
    }

    &.ba-blue:hover{
        background-color: $blue3;
        box-shadow: 0px 5px 20px $rgba2;
    }

    &.ba-green{
        background-color: $green16;
        color: $white1;
        @include useIconWhite;
    }

    &.ba-green:hover{
        box-shadow: 0px 5px 20px $rgba4;
    }

    &.ba-red{
        background-color: $red1;
        color: $white1;
        @include useIconWhite;
    }

    &.ba-red:hover{
        background-color: $red12;
        box-shadow: 0px 5px 20px $rgba3;
    }

    &.ba-orange{
        background-color: $orange1;
        color: $white1;
        @include useIconWhite;
    }

    &.ba-orange:hover{
        background-color: $orange2;
        box-shadow: 0px 5px 20px $skin1;
    }

    &.ba-dark{
        background-color: $black11;
        color: $white1;
        @include useIconWhite;
    }

    &.ba-dark:hover{
        box-shadow: 0px 5px 20px $rgba6;
    }

    &.ba-light{
        background-color: $light7;
        color: $black11;
        builderall-icon{
            --builderall-icon-color: var(--black11);
        }
    }

    &.ba-light:hover{
        box-shadow: 0px 5px 20px $rgba5;
    }

    // Disabled
    &.ba-disabled,
    &:disabled{
        filter: opacity(0.5) !important;
        cursor: not-allowed !important;
    }

    // Rounded
    &.ba-rounded{
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            position: absolute;
        }
    }

    // Special Buttons
    &.ba-btn-normal,
    &.ba-btn-success,
    &.ba-btn-trash,
    &.ba-btn-close,
    &.ba-btn-add{
        background-color: $light5;
        builderall-icon{
            --builderall-icon-color: var(--light15);
        }
    }

    &.ba-btn-normal{
        &:hover{
            background-color: $blue1;
            builderall-icon{
                --builderall-icon-color: var(--white1);
            }
        }
    }

    &.ba-btn-success{
        &:hover{
            background-color: $green1;
            builderall-icon{
                --builderall-icon-color: var(--white1);
            }
        }
    }

    &.ba-btn-trash{
        &:hover{
            background-color: $red9;
            builderall-icon{
                --builderall-icon-color: var(--red1);
            }
        }
    }

    &.ba-btn-close{
        background-color: transparent;
        &.ba-sm{
            builderall-icon{
                --builderall-icon-size: 30px;
            }
        }
        &.ba-md{
            builderall-icon{
                --builderall-icon-size: 40px;
            }
        }
        &.ba-lg{
            builderall-icon{
                --builderall-icon-size: 50px;
            }
        }
        &.ba-xl{
            builderall-icon{
                --builderall-icon-size: 60px;
            }
        }
        &:hover{
            builderall-icon{
                --builderall-icon-color: var(--dark5);
            }
        }
    }
    &.ba-btn-add{
        background-color: transparent;
        &.ba-sm{
            builderall-icon{
                --builderall-icon-size: 30px;
            }
        }
        &.ba-md{
            builderall-icon{
                --builderall-icon-size: 40px;
            }
        }
        &.ba-lg{
            builderall-icon{
                --builderall-icon-size: 50px;
            }
        }
        &.ba-xl{
            builderall-icon{
                --builderall-icon-size: 60px;
            }
        }
        &:hover{
            builderall-icon{
                --builderall-icon-color: var(--dark5);
            }
        }
    }
}
