/* Avatars */

// Variables
$sizeSm: 32px;
$sizeMd: 40px;
$sizeLg: 48px;
$sizeXl: 56px;

// Mixin
@mixin minMaxSize($size){
    max-width: $size;
    max-height: $size;
    min-width: $size;
    min-height: $size;
}

@mixin ba-notificationSizes($size,$top,$left){
    width: $size !important;
    height: $size !important;
    margin-top: $top;
    margin-left: $left;
}

// Style
.ba-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

    // Sizes
    &.ba-sm{
        .ba-image,.ba-letters{
            @include minMaxSize($sizeSm);
            font-size: 11.2px;
        }
        .ba-image{
            .ba-circle::before{
                @include ba-notificationSizes(8px,23px,-8px);
            }
        }
        .ba-letters{
            .ba-circle::before{
                @include ba-notificationSizes(8px,23px,23px);
            }
        }
    }

    &.ba-md{
        .ba-image,.ba-letters{
            @include minMaxSize($sizeMd);
            font-size: 16px;
        }
        .ba-circle::before{
            @include ba-notificationSizes(10px,28px,-10px);
        }
        .ba-letters{
            .ba-circle::before{
                @include ba-notificationSizes(10px,28px,28px);
            }
        }
    }

    &.ba-lg{
        .ba-image,.ba-letters{
            @include minMaxSize($sizeLg);
            font-size: 19.2px;
        }
        .ba-circle::before{
            @include ba-notificationSizes(12px,34px,-12px);
        }
        .ba-letters{
            .ba-circle::before{
                @include ba-notificationSizes(12px,34px,34px);
            }
        }
    }

    &.ba-xl{
        .ba-image,.ba-letters{
            @include minMaxSize($sizeXl);
            font-size: 22.4px;
        }
        .ba-circle::before{
            @include ba-notificationSizes(14px,39px,-13px);

        }
        .ba-letters{
            .ba-circle::before{
                @include ba-notificationSizes(14px,39px,39px);
            }
        }
    }

    // Style Configurations
    .ba-image img,.ba-letters{
        border-radius: 50%;
        background-color: $light13;
    }
    .ba-image{
        position: relative;
        img{
            width: -webkit-fill-available !important;
            padding: 6.4px;
        }
    }
    .ba-letters{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue1;
        font-size: 20.8px;
        padding: 0;
    }
    .ba-circle{
        position: absolute;
        right: 0;
        bottom: -4px;
    }
}