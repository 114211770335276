/* Spacing */

// Margin
.ba-mg-1{
    margin: 16px;
}

.ba-mg-2{
    margin: 32px;
}

.ba-mg-3{
    margin: 48px;
}

.ba-mg-4{
    margin: 64px;
}

// Margin Top
.ba-mg-t-1{
    margin-top: 16px;
}

.ba-mg-t-2{
    margin-top: 32px;
}

.ba-mg-t-3{
    margin-top: 48px;
}

.ba-mg-t-4{
    margin-top: 64px;
}

// Margin Right
.ba-mg-r-1{
    margin-right: 16px;
}

.ba-mg-r-2{
    margin-right: 32px;
}

.ba-mg-r-3{
    margin-right: 48px;
}

.ba-mg-r-4{
    margin-right: 64px;
}

// Margin Left
.ba-mg-l-1{
    margin-left: 16px;
}

.ba-mg-l-2{
    margin-left: 32px;
}

.ba-mg-l-3{
    margin-left: 48px;
}

.ba-mg-l-4{
    margin-left: 64px;
}

// Margin Bottom
.ba-mg-b-1{
    margin-bottom: 16px;
}

.ba-mg-b-2{
    margin-bottom: 32px;
}

.ba-mg-b-3{
    margin-bottom: 48px;
}

.ba-mg-b-4{
    margin-bottom: 64px;
}

// Margin Y
.ba-mg-y-1{
    margin: 16px 0;
}

.ba-mg-y-2{
    margin: 32px 0;
}

.ba-mg-y-3{
    margin: 48px 0;
}

.ba-mg-y-4{
    margin: 64px 0;
}

// Margin X
.ba-mg-x-1{
    margin: 0 16px;
}

.ba-mg-x-2{
    margin: 0 32px;
}

.ba-mg-x-3{
    margin: 0 48px;
}

.ba-mg-x-4{
    margin: 0 64px;
}

// Padding
.ba-pd-1{
    padding: 16px;
}

.ba-pd-2{
    padding: 32px;
}

.ba-pd-3{
    padding: 48px;
}

.ba-pd-4{
    padding: 64px;
}

// Padding Top
.ba-pd-t-1{
    padding-top: 16px;
}

.ba-pd-t-2{
    padding-top: 32px;
}

.ba-pd-t-3{
    padding-top: 48px;
}

.ba-pd-t-4{
    padding-top: 64px;
}

// Padding Right
.ba-pd-r-1{
    padding-right: 16px;
}

.ba-pd-r-2{
    padding-right: 32px;
}

.ba-pd-r-3{
    padding-right: 48px;
}

.ba-pd-r-4{
    padding-right: 64px;
}

// Padding Left
.ba-pd-l-1{
    padding-left: 16px;
}

.ba-pd-l-2{
    padding-left: 32px;
}

.ba-pd-l-3{
    padding-left: 48px;
}

.ba-pd-l-4{
    padding-left: 64px;
}

// Padding Bottom
.ba-pd-b-1{
    padding-bottom: 16px;
}

.ba-pd-b-2{
    padding-bottom: 32px;
}

.ba-pd-b-3{
    padding-bottom: 48px;
}

.ba-pd-b-4{
    padding-bottom: 64px;
}

// Padding Y
.ba-pd-y-1{
    padding: 16px 0;
}

.ba-pd-y-2{
    padding: 32px 0;
}

.ba-pd-y-3{
    padding: 48px 0;
}

.ba-pd-y-4{
    padding: 64px 0;
}

// Padding X
.ba-pd-x-1{
    padding: 0 16px;
}

.ba-pd-x-2{
    padding: 0 32px;
}

.ba-pd-x-3{
    padding: 0 48px;
}

.ba-pd-x-4{
    padding: 0 64px;
}

// Width
.ba-w-100{
    width: 100%;
}

.ba-w-75{
    width: 75%;
}

.ba-w-50{
    width: 50%;
}

// Height
.ba-h-100{
    height: 100%;
}

.ba-h-75{
    height: 75%;
}

.ba-h-50{
    height: 50%;
}