/* Switchs */

.ba-switch{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    .ba-checkbox{ 
        opacity: 0;
        width: 0;
        height: 0;
    }
    .ba-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }

    .ba-slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    .ba-checkbox:checked + .ba-slider {
        background-color: $green1;
    }

    .ba-checkbox:focus + .ba-slider {
        box-shadow: 0 0 1px $green1;
    }

    .ba-checkbox:checked + .ba-slider:before {
        transform: translateX(26px);
    }

    // Sizes
    &.ba-sm{
        width: 30px;
        height: 16.5px;
        .ba-slider:before{
            width: 12.1px;
            height: 12.5px;
            left: 2px;
            top: 2px;
        }
        .ba-checkbox:checked + .ba-slider:before {
            transform: translateX(13.4px);
        }
    }

    &.ba-md{
        width: 38px;
        height: 21px;
        .ba-slider:before{
            width: 15.3px;
            height: 15.9px;
            left: 2.5px;
            top: 2.8px;
        }
        .ba-checkbox:checked + .ba-slider:before {
            transform: translateX(17.2px);
        }
    }

    &.ba-lg{
        width: 52px;
        height: 28px;
        .ba-slider:before{
            width: 20.5px;
            height: 21.21px;
            left: 3px;
            top: 3.6px;
        }
        .ba-checkbox:checked + .ba-slider:before {
            transform: translateX(24.8px);
        }
    }

    &.ba-xl{
        width: 58px;
        height: 32px;
        .ba-slider:before{
            width: 23.3px;
            height: 24.2px;
            left: 3px;
            top: 4px;
        }
        .ba-checkbox:checked + .ba-slider:before {
            transform: translateX(27.8px);
        }
    }
}
