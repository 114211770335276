/* Overviews */

.ba-overview{
    display: flex;
    flex-direction: column;
    gap: 9.6px;
    padding: 12.8px;
    max-width: 188px;
    background-color: $light21;
    border-radius: 8px;
    align-items: center;
    margin-top: 90px;
    .ba-video-thumb{
        background-color: $white1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 159px;
        height: 107px;
        padding: 6.4px;
        border-radius: 8px;
        margin-top: -80px;
        position: relative;
        margin-bottom: 6.4px;
        box-shadow: 0px 4px 4px $rgba17;
        img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
        }
        .ba-button{
            position: absolute;
            right: -5px;
            bottom: -5px;   
            box-shadow: 0px 4px 4px $rgba17;
            border: none;
            background-color: $white1;
            border-radius: 2px;
            padding: 6.4px;
            cursor: pointer;
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            builderall-icon{
                --builderall-icon-size: 100%;
                --builderall-icon-color: var(--blue1);
            }
            svg{
                width: 100%;
                fill: $blue1;
            }
        }
    }
    .ba-title{
        color: $black9;
        font-size: 16px;
        font-family: $mulish;
        font-weight: 700;
        padding: 0 9.6px;
    }
    .ba-description{
        color: $black9;
        font-size: 12.8px;
        font-weight: 400;
        padding: 0 9.6px;
    }
	> .ba-btn { /* more info */
		box-sizing: border-box;
	}
}